
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonInput, AvSkeletonTable } from "@/components/av-skeleton";
import ENUMS from "@/enums";
import { systems } from "@/resources/systems-modules-events";
// Services
import { useAdministrator, useUser } from "@/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { IResponseGetProfiles } from "@core/services/interfaces/administrator/IAdministratorService";
// Interfaces
import { IOption, IUserData } from "@core/services/interfaces/IUtil";
import {
  IResponseList,
  IUserProfile,
} from "@core/services/interfaces/user/IUserService";
import { AxiosResponse } from "axios";
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BvTableFieldArray,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import { RouterLink } from "vue-router";
import vSelect from "vue-select";
import UsersListFilters from "./UsersListFilters.vue";

interface IUser {
  id: string;
  name: string;
  email: string;
  profile: IUserProfile[];
  status: boolean;
}

@Component({
  name: "UsersList",
  components: {
    UsersListFilters,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,
    RouterLink,
    AvButton,
    AvExportButton,
    AvSkeletonTable,
    AvSkeletonInput,
    vSelect,
    BCardText,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
})
export default class UsersList extends Vue {
  $ENUMS = ENUMS;
  perPage: number = 10;
  perPageOptions: Array<number> = [10, 25, 50, 100];
  currentPage: number = 1;
  display: number = 0;
  total: number = 0;
  searchQuery = "";
  sortBy = "";
  isSortDirDesc = "";
  users: IUser[] = [];
  filterOn = [];
  loading: boolean = false;
  loadingProfile: boolean = false;
  profileFilter: string | null = "";
  profileOptions: IOption[] = [];
  modalConfirmShow = false;
  loadingResetPassword = false;
  userPasswordResetId: string | null = null;
  statusFilter: boolean | null = null;
  statusOptions: IOption[] = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];
  $refs = {
    refListTable: {} as any,
  };

  tableColumns: BvTableFieldArray = [
    { key: "name", label: "Nome", sortable: true },
    { key: "email", label: "E-mail", sortable: true },
    { key: "profile", label: "Perfil", sortable: true },
    { key: "status", label: "Status", sortable: true },
    { key: "actions", label: "Ações" },
  ];

  // Computeds
  get dataMeta() {
    const localItemsCount = this.users ? this.users.length : 0;

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to:
        this.perPage * this.currentPage < this.display
          ? this.perPage * this.currentPage
          : this.display,
      of: this.display,
      total: this.total,
    };
  }

  get filteredUsers(): IUser[] {
    let users = this.users;
    if (this.profileFilter != "" && this.profileFilter != null) {
      users = users.filter(
        (user) =>
          user.profile.findIndex((it) => it.PerfilNome == this.profileFilter) >=
          0
      );
    }

    if (this.statusFilter != null) {
      users = users.filter((user) => user.status == this.statusFilter);
    }

    return users;
  }

  get currentUser(): IUserData | null {
    return JSON.parse(localStorage.getItem("userData") || "null");
  }

  get isUserAdmin(): boolean {
    return this.currentUser ? this.currentUser.ehAdmin : false;
  }

  get userProcessing(): string[] {
    return this.$store.getters["firebase/userProcessing"];
  }

  // lifeCycle
  async created() {
    await this.fetchProfile();
    this.refreshData();
  }

  // Watchs
  @Watch("filteredUsers")
  onFilteredUsers() {
    this.display = this.filteredUsers.length;
    this.currentPage = 1;
  }

  // Methods
  fetchProfile() {
    this.loadingProfile = true;
    useAdministrator
      .requestGetProfiles({ SistemaId: "54f1313e-afa1-4810-9e0c-31c574b3e484" })
      .then((response: AxiosResponse<IResponseGetProfiles>) => {
        this.profileOptions = response.data.data
          .filter((profile) => profile.status)
          .map((profile) => ({
            value: profile.nome,
            label: profile.nome,
          }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Perfis!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.profileOptions = [];
      })
      .finally(() => {
        this.loadingProfile = false;
      });
  }

  refreshData(): void {
    this.loading = true;

    useUser
      .requestList({
        draw: 1,
        length: 10000,
        paginate: false,
      })
      .then((response: IResponseList) => {
        this.users = response.data.data.map(
          (user): IUser => ({
            id: user.Id,
            name: user.Nome,
            email: user.Email,
            profile: user.Perfil,
            status: user.Status,
          })
        );

        this.display = this.users.length;
        this.total = this.users.length;
      })
      .catch((it) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: it.response.data,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onFiltered(filteredItems: Array<any>) {
    this.display = filteredItems.length;
    this.currentPage = 1;
  }

  showConfirmedRequestPasswordReset(id: string) {
    this.userPasswordResetId = id;
    this.modalConfirmShow = true;
  }

  fetchRequestPasswordReset() {
    if (this.userPasswordResetId) {
      this.loadingResetPassword = true;
      useAdministrator
        .requestRequestPasswordReset(this.userPasswordResetId)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Reset de senha solicitado!",
              text: "Solicite ao cliente a verificar o e-mail e seguir as instruções",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.modalConfirmShow = false;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro",
              text: "Não foi possível solicitar o reset de senha deste usuário, entre em contato com o administrador do sistema",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loadingResetPassword = false;
        });
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Ocorreu um erro",
          text: "Não foi possível solicitar o reset de senha deste usuário, entre em contato com o administrador do sistema",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  }

  editUser(id: string) {
    this.$router.push({ name: "admin-panel-users-edit", params: { id } });
  }

  reduceValue(option: IOption) {
    return option.value;
  }

  async getUsers() {
    return new Promise((resolve) => {
      const data = this.$refs.refListTable.filteredItems.map((user: IUser) => ({
        Nome: user.name,
        "E-mail": user.email,
        Perfil: user.profile,
        Status: user.status ? this.$ENUMS.TEXT.ATIVO : this.$ENUMS.TEXT.INATIVO,
      }));

      resolve(data);
    });
  }

  /**
   * Verifica se o usuário logado tem permissão de atualizar o usuário item com base nos perfis
   * de escopo do usuário logado e checando se o usuário em questão não é ele mesmo.
   * @param item
   */
  checkEnableEdit(item: IUser) {
    const escoped =
      this.profileOptions.findIndex((it) =>
        item.profile.join("; ").includes(it.label)
      ) >= 0 || this.isUserAdmin;
    let isCurrentUser = false;

    if (this.currentUser) {
      isCurrentUser = this.currentUser.id == item.id;
    }

    return escoped && !isCurrentUser;
  }

  resolverStatus(userId: string, status: boolean): string {
    if (this.userProcessing.includes(userId))
      return this.$ENUMS.LABEL.STATUS_PROCESSANDO;
    return status
      ? this.$ENUMS.LABEL.STATUS_ATIVO
      : this.$ENUMS.LABEL.STATUS_INATIVO;
  }

  resolveProfile(profiles: IUserProfile[]): string {
    const profilesThisSystem = profiles.filter(
      (it) => it.SistemaId == systems.Emplacamento.id
    );

    return profilesThisSystem.map((it) => it.PerfilNome).join(";");
  }
}
